/* global Chart:true, $:true */

import React from 'react';
import Utils from '../../../js/utils.js';

class ColorLegend extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.statView = this.statView.bind(this);
        this.analyzeData = this.analyzeData.bind(this);
        this.getDots = this.getDots.bind(this);
        this.getBody = this.getBody.bind(this);
        this.getStats = this.getStats.bind(this);
        this.showGraphPoint = this.showGraphPoint.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            dataPoints: {},
            showStats: false,
            showDetail: NaN,
            highestIndex: 0,
        };
    }

    statView() {
        this.setState(prevState => ({ showStats: !prevState.showStats }));
    }

    analyzeData(data) {
        if (Object.keys(data.data).length > 2 && Utils.isSet(data.stats)) {
            const { stats } = data;
            let count = 0;
            let highestIndex = 0;
            const newArr = [];
            for (let i = 0; i < 6; i++) {
                highestIndex = stats[i].length > 0 ? i : highestIndex;
                count += stats[i].length;
                newArr.push(stats[i]);
            }
            let last = [];
            for (let i = 6; i <= 10; i++) {
                highestIndex = stats[i].length > 0 ? 6 : highestIndex;
                count += stats[i].length;
                last = [...last, ...stats[i]];
            }
            newArr.push(last);
            const groupCount = newArr[highestIndex].length;
            this.setState({
                dataPoints: newArr,
                totalPacketCount: count,
                showDetail: (groupCount > 100 && highestIndex !== 6) || highestIndex === 0 ? NaN : highestIndex,
            });
        }
    }

    getDots() {
        const stats = this.state.dataPoints;
        const colors = Chart.colorField;
        const rects = [];
        let i = 0;
        const detailKey = this.state.showDetail;
        colors.forEach((color, index) => {
            const visible = Utils.isSet(stats) && stats[index].length > 0;
            const isActive = !Number.isNaN(detailKey) && index == detailKey;
            if (visible) {
                rects.push(
                    <a
                        key={index}
                        onClick={e => this.showGraphPoint(index)}
                        className={`color-trigger ${isActive ? 'active' : ''}`}
                    >
                        <span className='color-box'>
                            <svg>
                                <circle cx='5' cy='5' r='5' style={{ fill: color }} />
                            </svg>
                        </span>
                        <span className='desc'>{`${i}%${i == 60 ? '+' : ''}`}</span>
                    </a>,
                );
            }
            i += 10;
        });
        return rects;
    }

    getBody() {
        const data = this.state.dataPoints;
        if (Utils.isNotSet(data)) {
            return (<tbody />);
        }
        const colors = Chart.colorField;
        const lines = [];
        let i = 0;
        let isLight = 'light';
        const totalCount = this.state.totalPacketCount;
        const onePercent = totalCount / 100;
        colors.forEach((color, index) => {
            const lossInP = (data[index].length / onePercent).toFixed(2);
            if (lossInP > 0) {
                isLight = isLight === '' ? 'light' : '';
                lines.push(
                    <tr key={color} className={isLight}>
                        <td className='first color border-right'>
                            <svg style={{ opacity: 1 }}>
                                <circle cx='5' cy='5' r='5' style={{ fill: color }} />
                            </svg>
                            <span className='desc' style={{ color, opacity: 1 }}>{`${i}%`}</span>
                        </td>
                        <td className='count border-right'>
                            {data[index].length}
                        </td>
                        <td className='last count-in-perc'>{lossInP}</td>
                    </tr>,
                );
            }
            i += 10;
        });
        return (
            <tbody>
            {lines}
            </tbody>
        );
    }

    getStats() {
        const { stats } = this.props;
        const total = Utils.getPropertyOfObject(['count', 'total'], stats, '');
        const lost = Utils.getPropertyOfObject(['count', 'lost'], stats, '');
        const count = Utils.getPropertyOfObject(['count', 'count'], stats, '');
        const avg = Utils.getPropertyOfObject(['loss', 'avg'], stats, '');
        const max = Utils.getPropertyOfObject(['loss', 'max'], stats, '');
        return (
            <div className='stats'>
                <span>
                    <span>Total Packet Count:</span>
                    <span className='val'>{total}</span>
                </span>
                <span>
                    <span>Total Packet Loss Count:</span>
                    <span className='val'>{lost}</span>
                </span>
                <span>
                    <span>Agregation:</span>
                    <span className='val'>{count}</span>
                </span>
                <span>
                    <span>Average Loss:</span>
                    <span className='val'>{avg}</span>
                </span>
                <span>
                    <span>Maximum Loss: </span>
                    <span className='val'>{max}</span>
                </span>
            </div>
        );
    }

    showGraphPoint(val) {
        let index = val;
        let skip = false;
        if (index == this.state.showDetail) {
            index = NaN;
        } else {
            const size = this.props.graphData.stats[index].length;
            if (size > 1000) { // magic contstant
                const selector = $('.headline');
                const prev = selector.text();
                selector.text('Supported only for AGR.COUNT < 1000');
                setTimeout(() => {
                    selector.text(prev);
                }, 4000);
                skip = true;
            }
        }
        if (!skip) {
            this.setState({ showDetail: index });
        }
    }

    showPoints(key, props) {
        if (!Number.isNaN(key) && Utils.isSet(props.graphData.stats)) {
            const stamps = props.graphData.stats[key];
            let dataSeriesIndex = 0;
            if (props.graphType == 'advanced') {
                dataSeriesIndex = 8;
            }
            const plots = [];
            const opacityKeys = [0.2, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3];
            const color = Chart.hexToRgbA(Chart.colorField[key], opacityKeys[key]);
            stamps.forEach((stamp, index) => {
                plots.push({
                    color,
                    width: 2,
                    value: stamp,
                    zIndex: 10,
                    id: stamp / 1000,
                });
            });
            const { chart } = props;
            chart.xAxis[0].update({ plotLines: plots });
        } else {
            const { chart } = props;
            chart.xAxis[0].update({ plotLines: [] });
        }
    }

    componentWillMount() {
        const data = this.props.graphData;
        if (Utils.isSet(data)) {
            this.analyzeData(data);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.graphData != nextProps.graphData && Utils.isSet(nextProps.graphData)) {
            this.analyzeData(nextProps.graphData);
        }
        return this.props.chart != nextProps.chart || this.state != nextState;
    }

    render() {
        const triggerVisibility = this.state.showStats ? 'hidden' : 'visible';
        const statVisibility = this.state.showStats && Utils.isSet(this.state.dataPoints) ? 'visible' : 'hidden';
        const chevronDirection = this.state.showStats ? 'up' : 'down';
        if (Utils.isSet(this.props.chart) && Utils.isSet(this.props.graphData)) {
            this.showPoints(this.state.showDetail, this.props);
        }
        return (
            <div className='color-legend'>
                <span className='headline'>PACKET LOSS - SELECT TO HIGHLIGHT</span>
                <div className='line first-visible'>
                    <div className='legend-toggle rects inline'>
                        { this.getDots() }
                    </div>
                    <a className='stat-trigger' onClick={this.statView}>
                        <span className='show-hide '>
                            STATS
                            {' '}
                            <i className={`fa fa-chevron-${chevronDirection}`} />
                        </span>
                    </a>
                </div>
                <div className={`stat-table ${statVisibility}`}>
                    {this.getStats()}
                    <table>
                        <thead>
                        <tr>
                            <th className='border-right'>Group</th>
                            <th className='border-right'>Agr Count</th>
                            <th>%</th>
                        </tr>
                        </thead>
                        {this.getBody()}
                    </table>
                </div>
            </div>
        );
    }
}

export default ColorLegend;
