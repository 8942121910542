import React from 'react';
import EpochPicker from '../epochPicker.jsx';


const GraphHeader = (props) => {
    const instanceName = window.location.host.split('-')[0];
    const logoName = 'cocopacket-logo' + (props.themeLabel === 'light' ? '_black.png' : '_inverse.svg');
    return (
        <div className={"header head-banner " + (props.isMobile ? 'mob':'')}>
            {props.showInfo && !props.isIframe &&
                <EpochPicker epoch={props.ipDetails.epoch}
                             setEpochWithEndpoint={props.setEpochWithEndpoint}
                             resetButton={props.resetButton}
                             resetZoom={props.resetZoom}
                             isMobile={props.isMobile}
                />
            }
            <div className="powered">
                <a className={'home ' + (props.isMobile ? 'center':'')}
                   href='https://cocopacket.com'
                   rel="noreferrer noopener"
                   target="_blank"
                >
                    <span id="pow"> powered by</span>
                    <img alt={"logo_" + props.themeLabel}
                         key={"logo_" + props.themeLabel}
                         src={"/custom/"+instanceName+"/logo.svg"}
                         onError={(e) => e.target.src='/less/img/' + logoName}
                    />
                </a>
            </div>
        </div>
    );
}

export default GraphHeader;