import React from 'react';

const Error = () => (
    <div className="error404">
        <h2 >Ups, something went wrong error 404 (incorrect route).</h2>
        <h3 className="wrong-params-msg">
            <p>Parameters in the url are wrong.</p>
            <p>
                <strong>Url must be in a correct order</strong> <br/>
                #/ip=ip_hash&type=_type_&epoch=_epoch_&slave=slave_name <br/>
                _type_ - graph's type = basic/advanced <br/>
                _epoch_ - number of seconds, e.g. 1day = 86400
                <br/>
                <br/>
                <strong>Optional parameters at the end of the url are not order dependant</strong> <br/>
                &info = info/noinfo <br/>
                &iframe = yes <br/>
                &theme = light <br/>
            </p>
            <p>
                <strong>Full URL example</strong> <br/>
                #/ip=ip_hash&type=basic&epoch=86400&slave=slave_name&info=info&iframe=no&theme=light <br/>
            </p>
        </h3>
    </div>
);

export default Error;