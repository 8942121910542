/* global Chart:true, $:true, isNaN:true */

import hasher from 'hasher';

const Utils = {
    logUrl: false,
    defColor: '#486F97',
    apiVersion: 'v1',

    // ------------------------------ common functions -----------------------------------

    setHashSilently(hash) {
        hasher.changed.active = false; // disable changed signal
        hasher.setHash(hash); // set hash without dispatching changed signal
        hasher.changed.active = true; // re-enable signal
    },

    getTimeNow() {
        return Math.round(new Date().getTime() / 1000);
    },

    getPropertyOfObject(path, original, end=undefined) {
        let obj = {...original};
        // safely obtain deep property in object by using reducer
        // const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const reducer = (xs, x) => {
            // console.log('%c -- accumlator ', 'color: orange');
            // console.log(xs);
            // console.log('%c -- currentValue ', 'color: red');
            // console.log(x);
            return (xs && xs[x]) ? xs[x] : end ;
        };
        return path.reduce(reducer, obj);
    },

    formatTime(ts, isUtc=false) {
        const timestamp = this.convertTimeToJS(ts);
        const d = new Date(timestamp);
        return this.formatTimeToString(d, isUtc, true, true);
    },

    formatTimeToString(d, isUTC, includeDate = true, includeSeconds = false) {
        let out = '';
        const date = isUTC? d.getUTCDate() : d.getDate();
        const month = isUTC? d.getUTCMonth() : d.getMonth();
        const hours = isUTC? d.getUTCHours() : d.getHours();
        const minutes = isUTC? d.getUTCMinutes() : d.getMinutes();
        const seconds = isUTC? d.getUTCSeconds() : d.getSeconds();
        if (includeDate) { out = `${date}.${this.getMonthName(month, true)}`; }
        out += `   ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        if (includeSeconds) { out += `:${seconds < 10 ? '0' : ''}${seconds}`; }
        return out;
    },

    convertTimeToJS(time) {
        if (time.toString().length < 13) {
            return time * 1000;
        }
        return time;
    },

    getLossColor(lossColor) {
        if (lossColor == undefined) {
            return Utils.defColor;
        }
        const loss = Number(lossColor);
        if (loss == -1) {
            return '#FF5733';
        }
        let colorIndex = 0;
        if (loss.toFixed(1) > 0.1) {
            colorIndex = Math.ceil(loss / 10);
            colorIndex = colorIndex > 6 ? 6 : colorIndex;
        }
        return Chart.colorField[colorIndex];
    },

    getContentWidth() {
        return Number(window.innerWidth);
    },

    getChartLineWidth(isMob = false) {
        if (isMob) {
            return Math.round(window.innerWidth - 54);
        }
        const basewidth = this.getContentWidth();
        return Math.round(((basewidth * 0.95) * 0.95) - 75);
    },

    formatNumber(n, floatPart = 2, delimeter = ' ') {
        const num = Number(n);
        const tofixed = num.toFixed(floatPart);
        return tofixed.replace(/./g, (c, i, a) => (i && c !== '.' && ((a.length - i) % 3 === 0) ? delimeter + c : c));
    },

    getMonthName(index, shortVersion = true) {
        const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'];
        const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return shortVersion ? month_names_short[index] : month_names[index];
    },


    isSet(value) {
        if (value == null) {
            return false;
        }
        const val = typeof value;
        switch (val) {
            case 'object':
            case 'string':
                return Object.keys(value).length > 0;
            case 'undefined':
                return false;
            default:
                return true;
        }
    },

    isNotSet(value) {
        return !this.isSet(value);
    },

    copyEffect(selector) {
        selector.css('color', '#19e880');
        setTimeout(() => {
            selector.css('color', '#486F97');
        }, 2000);
    },

    // -------------------------------------- API CALL WRAPPER ---------------------------------------------------------


    formApiData(url, async, dataType, successCallback, errorCallback) {
        if (!url.startsWith('/')) {
            url = `/${url}`;
        }
        return {
            url,
            async,
            dataType,
            successCallback,
            errorCallback,
        };
    },

    performGETcall(call, type = 'GET', loguser = true) {
        if (Utils.logUrl) {
            console.log(call.url);
        }
        $.ajax({
            url: `/${Utils.apiVersion}${call.url}`,
            type,
            async: call.async,
            dataType: call.dataType,
            success(data) {
                const error = data.result === 'error';
                const isNotLogged =  error && (data.error === 'auth required' || data.error === 'admin auth required');
                if (isNotLogged && loguser) { Utils.logUser(false); }
                // ERROR CALLBACK
                if (error && Utils.isSet(call.errorCallback)) { call.errorCallback(data); }
                // SUCCESS CALLBACK
                if (!error && Utils.isSet(call.successCallback)) { call.successCallback(data); }
            },
            error(data) {
                // ERROR CALLBACK
                if (Utils.isSet(call.errorCallback)) { call.errorCallback(data); }
            },
        });
    },
};
// ------------------------------------------------------------------------------------------------------------
export default Utils;
