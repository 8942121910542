import React from 'react';
import moment from 'moment';
import SVGInline from 'react-svg-inline';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Utils from '../../../js/utils.js';
import 'bootstrap-daterangepicker/daterangepicker.css';
import iconChevronDown from '../../../../less/libs/icons/chevron_down.svg';

class EpochPicker extends React.Component {
    constructor(props) {
        super(props);
        this.fastDial = [1, 6, 12, 24, 48, 72, 168];
        this.fastDialDesc = ['1H', '6H', '12H', '1D', '2D', '3D', '1W'];
        this.state = { fastDial: this.fastDial.includes(Number(this.props.epoch)/3600) };
    }

    onApply = (ev, picker) => {
        const start = Math.round(picker.startDate._d.getTime() / 1000);
        const end = Math.round(picker.endDate._d.getTime() / 1000);
        this.setTime(end, start, picker);
        this.setState({ fastDial: false });
    }

    setTime = (end, start, picker) => {
        const epoch = end - start;
        const type = (picker != undefined);
        this.props.setEpochWithEndpoint(epoch, end, type);
    }

    fastDialAction = (e) => {
        const val = e.target.getAttribute('value');
        const end = Utils.getTimeNow();
        const start = end - (val * 3600);
        this.setTime(end, start);
        this.setState({ fastDial: true });
    }

    onHideCalendar = () => {
        const datepickerWrapper = $(this.refs.datepickerWrapper);
        datepickerWrapper.removeClass('closed');
        datepickerWrapper.addClass('open');
    }

    onShowCalendar = () => {
        const datepickerWrapper = $(this.refs.datepickerWrapper);
        datepickerWrapper.removeClass('open');
        datepickerWrapper.addClass('closed');
    }

    getEpochInput = () => {
        return (
            <DateRangePicker
                locale={{ format: 'D MMM, YYYY', firstDay: 1 }}
                cancelClass='hidden'
                parentEl='graph-wrap'
                startDate={moment()}
                endDate={moment()}
                maxDate={moment()}
                showDropDowns={false}
                alwaysShowCalendars
                timePicker={false}
                autoUpdateInput={false}
                onShow={this.onShowCalendar}
                onHide={this.onHideCalendar}
                onApply={this.onApply}
            >
                <div className='inner-block' id='calendarHover'>
                    <a ref='datepickerWrapper' className='side-action-button' onClick={this.sideButtonAction}>
                        <SVGInline svg={iconChevronDown} className='chevron ' />
                    </a>
                    <input
                        type='text'
                        ref='dateRangePicker'
                        className='datepicker-trigger'
                        readOnly='readonly'
                    />
                </div>
            </DateRangePicker>
        );
    }

    getFastDial = () => {
        const fastDialArr = [];
        const epoch = Number(this.props.epoch) / 3600;
        this.fastDial.forEach((val, i) => {
            fastDialArr.push(
                <a
                    key={val}
                    className={this.state.fastDial && epoch == val ? 'active' : ''}
                    onClick={this.fastDialAction}
                    value={val}
                >
                    {this.fastDialDesc[i]}
                </a>
            );
        });
        return (
            <div className='fast-dial'>
                {fastDialArr}
            </div>
        );
    }

    render(){
        return (
            <div className="header-block time-block-wrap">
                {this.getFastDial()}
                {!this.props.isMobile &&
                    <div className={"calendar-block "  + (this.state.fastDial?"":"active")}>
                        {this.getEpochInput()}
                    </div>
                }
            </div>
        )
    }
}

export default EpochPicker;
