import React from 'react';
import GraphHeader from './header.jsx';
import GraphHeaderMobile from './headerMobile.jsx';

const HeaderWrap = (props) => {
    const isFlop = window.location.hash.includes('flop');
    if(isFlop) return null;
    if(props.isMobile){
        return (<GraphHeaderMobile {...props}/>)
    }
    return (<GraphHeader {...props}/>)
}

export default HeaderWrap;
