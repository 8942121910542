import React from 'react';
import EpochPicker from '../epochPicker.jsx';

const GraphHeaderMobile = (props) => (
    <div className="header mobile">
        <a className="home" href='https://cocopacket.com'>
            <img id="icon-logo" src={"/less/img/cocopacket-logo" + (props.themeLabel === 'light' ? '_black.png' : '_inverse.svg') }/>
        </a>
        <EpochPicker epoch={props.ipDetails.epoch}
                     setEpochWithEndpoint={props.setEpochWithEndpoint}
                     resetButton={props.resetButton}
                     resetZoom={props.resetZoom}
                     isMobile={props.isMobile}
        />
    </div>
);

export default GraphHeaderMobile;