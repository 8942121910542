import React from 'react';
import GraphContainer from './../graph/graphContainer.jsx';
import update from 'immutability-helper';
import Utils from '../../js/utils.js';

class MainPanelDetail extends React.Component{

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = (props = this.props) => {
        const data = props.graph;
        let epoch = Utils.isSet(data) && Utils.isSet(data.epoch) ? data.epoch : 86400;
        epoch = epoch < 60 ? 60 : epoch;
        const customEpoch = Utils.isSet(data) && Utils.isSet(data.endPoint);
        return {
            ipDetails: {
                epoch,
                probe: data.probe,
                endPoint: customEpoch ? data.endPoint : Utils.getTimeNow(),
                graphType: Utils.isSet(data) && Utils.isSet(data.graphType) ? data.graphType : 'basic',
                accuracy: 'normal',
                customEpoch
            },
        };
    }

    // setters --------------------------------------------------
    setActiveProbe = (data) => {
        const newState = update(this.state, { ipDetails: { probe: { $set: data } } });
        this.setState(newState);
    }

    setGraphType = (type) => {
        const newState = update(this.state, { ipDetails: { graphType: { $set: type } } });
        this.setState(newState);
    }

    setAccuracyType = (type) => {
        const newState = update(this.state, { ipDetails: { accuracy: { $set: type } } });
        this.setState(newState);
    }

    setEpochWithEndpoint = (epoch, endpoint, epochType) => {
        epoch = epoch < 60 ? 60 : epoch;
        const newState = update(this.state, {
            ipDetails: {
                customEpoch: { $set: epochType },
                epoch: { $set: epoch },
                endPoint: { $set: endpoint },
            },
        });
        this.setState(newState);
    }

    setEndpointAndProbe = (endpoint, probe) => {
        const newState = update(this.state, {
            ipDetails: {
                probe: { $set: probe },
                endPoint: { $set: endpoint },
            },
        });
        this.setState(newState);
    }

    setEpochFromZoom = (epoch, to, res) => {
        epoch = epoch < 60 ? 60 : epoch;
        const newState = update(this.state, {
            ipDetails: {
                epoch: { $set: epoch },
                endPoint: { $set: to },
                customEpoch: { $set: res },
            },
        });
        this.setState(newState);
    }
    // ----------------------------------------------------------

    generateURL = () => {
        if (!this.props.isIframe) {
            const graph = this.state.ipDetails;
            const ip = this.props.selectedIP;
            let url = `ip=${ip}&`;
            url += `type=${graph.graphType}&`;
            url += `epoch=${graph.epoch}&`;

            if (graph.customEpoch) {
                url += `end=${graph.endPoint}&`;
            }
            url += `slave=${graph.probe.split('=')[1]}&`;
            url += 'info=' + (this.props.showInfo? 'info': 'noinfo');
            if(this.props.isIframe){
                url += '&iframe=yes'
            }
            const theme = Utils.getPropertyOfObject(['theme', 'label'], this.props, '');
            if(theme === 'light'){
                url += '&theme=light';
            }
            Utils.setHashSilently(url);
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.graph != nextProps.graph) {
            if (Utils.isSet(nextProps.graph)) {
                this.setState(this.getInitialState(nextProps))
            }
        }
    }

    render(){
        return(
            <div className={'main-panel' + (this.props.isIframe?' iframe' : '')}>
                <GraphContainer
                    ref='graph'
                    generateURL={this.generateURL}
                    graphConfig={this.props.graph}
                    {...this.props}
                    ipDetails={this.state.ipDetails}
                    setActiveProbe={this.setActiveProbe}
                    setGraphType={this.setGraphType}
                    setAccuracyType={this.setAccuracyType}
                    setEpochWithEndpoint={this.setEpochWithEndpoint}
                    setEndpointAndProbe={this.setEndpointAndProbe}
                    setEpochFromZoom={this.setEpochFromZoom}
                />
            </div>
        );
    }
};

export default MainPanelDetail;
