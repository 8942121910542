/* global Chart:true */
import React from 'react';
import Utils from '../../../js/utils.js';
import 'react-tippy/dist/tippy.css'
import { withTooltip } from 'react-tippy';

class AsOut extends React.Component {

    constructor(props) {
        super(props);
        this.state = {data: null};
    }

    loadData(){
        const url = `/lasttrace/${this.props.ip}?${this.props.probe}`;
        const successCallback = (data) => {this.setState({ data })}
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getAsPath = () => {
        const colors = Chart.colorField;
        const asArray = [];
        const { data } = this.state;
        const asPath = [];
        const asUniq = [];
        const arrData = Object.values(data)[0];
        arrData.forEach((entry) => {
            let pl = 0;
            // this usually is one entry, but when it is not the PL has to be pre-calculated
            entry.forEach((as) => {
                pl += Number(as.received);
            });
            pl = 100 - pl;
            entry.forEach(({ as, host }) => {
                if (asUniq[asUniq.length - 1] !== as) {
                    asUniq.push(as);
                    asPath.push({ name: as, pl, host });
                }
            });
        });
        const firstAsPath = asPath.find(as => (as.name !== 60068 && as.name !== -1 && as.name !== 39392));
        if(firstAsPath === undefined) {
            return (
                <div className={'asOut__entry'} key={'first-as'}>
                    <span className={'asOut__wrap'}>
                        <span className={'asOut__title'}>
                            First AS Out:
                        </span>
                        <span className={'asOut__value asOut__value--italic'}>
                            unknown
                        </span>
                    </span>
                </div>

            );
        }
        let colorIndex = firstAsPath.pl / 10;
        colorIndex = Math.ceil(colorIndex);
        colorIndex = colorIndex >= colors.length ? colors.length - 1 : colorIndex;
        const color = { color: colors[colorIndex] };
        const InfoIcon = () => (<i className='fa fa-info-circle' />);
        const tooltipOptions = {
            style: { marginLeft: '5px' },
            theme: 'dark',
            position: 'top',
            animation: 'perspective',
            title: `Description: ${firstAsPath.host}`,
        };
        const InfoTool = withTooltip(InfoIcon, tooltipOptions);
        asArray.push(
            <div className={'asOut__entry'} key={'first-as'}>
                <span className={'asOut__wrap'}>
                    <span className={'asOut__title'}>
                        First AS Out:
                    </span>
                    <span className={'asOut__value'}>
                        {'AS'+firstAsPath.name}
                        <InfoTool />
                    </span>
                </span>
                <span className={'asOut__row'}>
                    <span className={'asOut__title'}>
                        PL:
                    </span>
                    <span className={'asOut__value'} style={color}>
                        {firstAsPath.pl + '%'}
                    </span>
                </span>
            </div>,
        );
        return asArray;
    }

    componentDidMount(){
        this.loadData();
    }

    render() {
        if (Utils.isNotSet(this.state.data)){return null};
        return (
            <div className='asOut'>
                {this.getAsPath()}
            </div>
        );
    }
}

export default AsOut;
