import React from 'react';
import Clipboard from 'clipboard';

class InnerGraphHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graphType: this.props.ipDetails.graphType,
            accuracy: this.props.ipDetails.accuracy,
            zoomLevel: this.props.zoomLevel,
            isUTC: this.props.isUTC
        };
    }

    onChangeHandlerGraphType = () => {
        const type = this.state.graphType == 'basic' ? 'advanced' : 'basic';
        this.changeGraphType(type);
    }

    onChangeHandlerChangeAccuracy = () => {
        const accuracy = this.state.accuracy == 'normal' ? 'high' : 'normal';
        this.changeGraphAccuracy(accuracy);
    }

    onChangeHandlerChangeZoom = () => {
        const zoom = this.state.zoomLevel === 0 ? 1 : 0;
        this.changeZoomLevel(zoom);
    }

    changeGraphType = (graphType) => {
        this.setState({ graphType });
        this.props.setGraphType(graphType);
    }

    changeGraphAccuracy = (accuracy) => {
        this.setState({ accuracy });
        this.props.setAccuracyType(accuracy);
    }

    changeZoomLevel = (zoomLevel) => {
        this.setState({ zoomLevel });
        this.props.setZoom(zoomLevel);
    }

    changeUTC = (isUTC) => {
        this.setState({ isUTC });
        this.props.setUTC(isUTC);
    }

    onChangeUTC = () => {
        const isUTC  = !this.state.isUTC;
        this.changeUTC(isUTC);
    }

    getSwitches = () => {
        const isSmoke = this.state.graphType == 'advanced';
        const isHigh = this.state.accuracy == 'high';
        const { isUTC } = this.state;
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} onClick={() => this.changeGraphType('basic')}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerGraphType} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} onClick={() => this.changeGraphType('advanced')}>
                        Smoke
                    </span>
                </div>
                <div className='switch'>
                    <span className={isHigh ? '' : 'active'} onClick={() => this.changeGraphAccuracy('normal')}>
                        Normal
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerChangeAccuracy} checked={isHigh} />
                        <span className='slider round' />
                    </label>
                    <span className={isHigh ? 'active' : ''} onClick={() => this.changeGraphAccuracy('high')}>
                        High
                    </span>
                </div>
                <div className='switch'>
                    <span className={isUTC ? '' : 'active'} onClick={() => this.changeUTC(false)}>
                        LOCAL
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeUTC} checked={isUTC} />
                        <span className='slider round' />
                    </label>
                    <span className={isUTC ? 'active' : ''} onClick={() => this.changeUTC(true)}>
                        UTC
                    </span>
                </div>
            </div>
        );
    }

    getZoomSwitch = () => {
        const { zoomLevel } = this.state;
        return (
            <div className="range-slider">
                <span className='fa fa-minus' onClick={() => this.changeZoomLevel(0)} />
                <input
                    type='range'
                    name="points"
                    min="0"
                    max='1'
                    onChange={this.onChangeHandlerChangeZoom}
                    value={zoomLevel}
                />
                <span className="fa fa-plus" onClick={() => this.changeZoomLevel(1)} />
            </div>
        );
    }

    copyIPAction = (e) => {
        let scope = this;
        let selector = $(this.refs['ip-title']);
        let color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(function(){
            selector.text(scope.props.ipName);
            selector.css('color', color);
        },2000)
    }

    componentDidUpdate(){
        var clipboard = new Clipboard('.copy-btn');
    }

    render(){
        const probe = this.props.ipDetails.probe.split('=')[1];
        return (
            <div className={`panel ${this.props.isIframe?'isIframe':''}`}>
                <div className="title-wrap">
                        <span className="title">
                            <a  onClick={this.copyIPAction}>
                                <span
                                    ref="ip-title"
                                    data-clipboard-text={this.props.selectedIP}
                                    className={"ip ip-info copy-btn " + (this.props.ipName === 'no-title' ? 'no-title': '') }>
                                    {this.props.ipName + ' – ' + probe}
                                </span>
                            </a>
                        </span>
                </div>

                {this.props.showInfo && !this.props.isIframe &&
                    <div className="controls-wrap">
                        {this.getZoomSwitch()}
                        {this.getSwitches()}
                    </div>
                }
            </div>
        );
    }
};

export default InnerGraphHeader;
