/* global $:true, Chart:true */
import React from 'react';
import Utils from '../../../js/utils.js';

class TraceTableMobile extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getTraces = this.getTraces.bind(this);
        this.setOpenIndex = this.setOpenIndex.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { selectedIndex: NaN };
    }

    getTraces() {
        const traces = [];
        const { data } = this.props;
        const hops = [];
        const key = 0;
        let emptyCount = 0;
        const { selectedIndex } = this.state;
        for (let hopIndex in data) {
            let hopField = data[hopIndex];
            if (hopField.length > 0) {
                emptyCount = 0;
            }
            if (hopField.length == 0 && emptyCount < 4) {
                emptyCount++;
                hopField = [{
                    ip: '*', host: '*', received: 0, avg: '*', min: '*', max: '*', as: '*',
                }];
            }
            for (const hop in hopField) {
                const hopData = hopField[hop];
                if (hop == 0) {
                    var count = 0;
                    if (hopField.length > 1) {
                        for (const h in hopField) {
                            count += hopField[h].received;
                        }
                    } else {
                        count = hopData.received;
                    }
                }
                const colorLoss = Utils.getLossColor(100 - count);
                const plStyle = { border: `solid 2px${colorLoss}` };
                if (hopIndex == selectedIndex) {
                    hops.push(
                        <div
                            className='trace-box'
                            style={{ minHeight: '25px' }}
                            key={hopIndex}
                            data-key={hopIndex}
                            onClick={this.setOpenIndex}
                        >
                            <div className='record'>
                                <span className='text-color'>
                                    AVG
                                    {' '}
                                    <span className='in-value'>{hopData.avg}</span>
                                    MAX
                                    {' '}
                                    <span className='in-value'>{hopData.max}</span>
                                </span>
                            </div>
                            <div className='pl-box' style={plStyle}>
                                <span className='small'>PL</span>
                                <span className='val'>{`${100 - count}%`}</span>
                            </div>
                        </div>,
                    );
                } else {
                    hops.push(
                        <a className='trace-box' key={hopIndex} data-key={hopIndex} onClick={this.setOpenIndex}>
                            <div className='record'>
                                <span>
                                    <span className='text-color in-value'>
                                        IP
                                        {' '}
                                        <span className='in-value'>{hopData.ip}</span>
                                    </span>
                                    <span className='text-color in-value'>
                                        AS
                                        {' '}
                                        <span className='in-value'>{hopData.as}</span>
                                    </span>
                                </span>
                                <span className='text-color'>
                                    HOST
                                    {' '}
                                    <span>{hopData.host}</span>
                                </span>
                            </div>
                            <div className='pl-box' style={plStyle}>
                                <span className='small'>PL</span>
                                <span className='val'>{`${100 - count}%`}</span>
                            </div>
                        </a>,
                    );
                }
                hopIndex = '';
            }
        }
        traces.push(
            <div className='trace-table' key='0'>
                {hops}
            </div>,
        );
        return traces;
    }

    setOpenIndex(e) {
        // $(e.target).css('border', 'solid red');
        let id = e.target.getAttribute('data-key');
        id = id == this.state.selectedIndex ? NaN : id;
        this.setState({
            selectedIndex: id,
        });
    }

    getBar(received) {
        const barColor = 'bar-red';
        const style = { width: `${100 - received}%` };
        return (
            <div className='hop-perc'>
                <div className='bar'>
                    <div className={barColor} style={style} />
                </div>
                <span className='text-percentage'>
                    {100 - received}
                    %
                </span>
            </div>
        );
    }

    render() {
        const time = new Date(this.props.time * 1000);
        const leadingZero = time.getMinutes() < 10 ? '0' : '';
        const timeStr = `${time.getDate()}.${time.getMonth() + 1} 
                         ${time.getHours()}:${leadingZero}${time.getMinutes()}`;
        return (
            <div className='trace-data-record'>
                <span className='headline'>{`${timeStr}    Last Traceroute`}</span>
                {this.getTraces()}
            </div>
        );
    }
}

export default TraceTableMobile;
